<template>
  <div class="auth">
    <div class="auth_btn">
      <el-button type="primary" @click="handleOpenDialog(1)"> 新增 </el-button>
    </div>
    <Table
      :loading="loading"
      :rowHeader="rowHeader"
      :data="data"
      :pageShow="pageShow"
    />
    <el-dialog
      :title="`${
        dialogType === 1 || dialogType === 2
          ? '新增'
          : dialogType === 3
          ? '编辑'
          : '删除'
      }权限`"
      :visible.sync="dialogVisible"
      width="30%"
      :before-close="handleClose"
    >
      <div v-if="dialogVisible">
        <AddAuth
          v-if="dialogType === 1 || dialogType === 2"
          :dialogType="dialogType"
          :paramsObj="paramsObj"
          :handleClose="handleClose"
          :getPermissionInsert="getPermissionInsert"
        />
        <EditAuth
          v-else-if="dialogType === 3"
          :handleClose="handleClose"
          :paramsObj="paramsObj"
          :getPermissionUpdate="getPermissionUpdate"
        />
        <DeleteAny
          v-else
          :handleClose="handleClose"
          :name="paramsObj.label"
          :typeName="typeName"
          :getDelFunc="getPermissionDelete"
        />
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "Auth",
  components: {
    Table: () => import("@/components/Table"),
    DeleteAny: () => import("@/components/DeleteAny"),
    AddAuth: () => import("./components/AddAuth"),
    EditAuth: () => import("./components/EditAuth"),
  },
  data() {
    return {
      pageShow: false,
      loading: false,
      rowHeader: [
        {
          prop: "label",
          label: "名称",
          width: "120",
        },
        {
          prop: "url",
          label: "url",
        },
        {
          prop: "remark",
          label: "说明",
        },
        {
          prop: "btn",
          label: "操作",
          width: "250",
          render: (h, params) => {
            return (
              <div class="btn_box">
                {!params.row.parentId && (
                  <span
                    class="btn"
                    onClick={() => this.handleOpenDialog(2, params.row)}
                  >
                    新增
                  </span>
                )}
                <span
                  class="btn"
                  onClick={() => this.handleOpenDialog(3, params.row)}
                >
                  编辑
                </span>
                <span
                  class="btn"
                  onClick={() => this.handleOpenDialog(4, params.row)}
                >
                  删除
                </span>
              </div>
            );
          },
        },
      ],
      data: [],
      dialogVisible: false,
      dialogType: 1,
      paramsObj: {},
      typeName: "权限",
    };
  },
  created() {
    this.$emit("tabIndex", "2-2");
    this.getPermissionTree();
  },
  methods: {
    // 查询权限
    async getPermissionTree() {
      this.loading = true;
      const { data } = await this.$api.getPermissionTree();
      this.data = data;
      this.loading = false;
    },
    // 删除权限
    async getPermissionDelete() {
      let ids = JSON.stringify([this.paramsObj.id]);
      const res = await this.$api.getPermissionDelete({ rePermIds: ids });
      this.$message({
        message: res.message,
        type: res.status === 200 ? "success" : "warning",
        duration: 1500,
      });
      this.getPermissionTree();
      this.handleClose();
    },
    // 新增权限
    async getPermissionInsert(authObj) {
      const res = await this.$api.getPermissionInsert(authObj);
      this.$message({
        message: res.message,
        type: res.status === 200 ? "success" : "warning",
        duration: 1500,
      });
      this.getPermissionTree();
      this.handleClose();
    },
    // 更新权限
    async getPermissionUpdate(authObj) {
      const res = await this.$api.getPermissionUpdate(authObj);
      this.$message({
        message: res.message,
        type: res.status === 200 ? "success" : "warning",
        duration: 1500,
      });
      this.getPermissionTree();
      this.handleClose();
    },
    handleOpenDialog(id, row) {
      if (id === 1) {
        this.paramsObj = {};
      } else {
        this.paramsObj = row;
      }
      this.dialogType = id;
      this.dialogVisible = true;
    },
    handleClose() {
      this.dialogVisible = false;
    },
  },
};
</script>

<style lang="scss" scoped>
.auth {
  display: inline-block;
  width: calc(100% - 40px);
  min-height: calc(100vh - 110px);
  margin: 20px;
  box-sizing: border-box;
  padding: 20px;
  background-color: #fff;
  text-align: center;
  &_btn {
    text-align: right;
    margin-bottom: 20px;
  }
}
</style>
